
import { computed, defineComponent, reactive, ref, watch, onMounted, nextTick } from "vue";
import useFetchSearch from "./hooks/useFetchSearch";
import { STATUS } from "@/utils";
import useGetTableData from "@/composables/useGetTableData";
import { useRouter, useRoute } from "vue-router";
import IterationTip from "@/components/iteration-tip/index.vue";
import ReportContent from "./components/reportContent.vue";
import { updateInterId } from "@/views/workbench/useMixin";
import FullScreen from "@/components/fullscreen/index.vue";
import dayjs from "dayjs";
import useBoardBase, { MultipleDemandItem, MultipleStaffItem } from "@/composables/useBoardBase";
import { SearchArray } from "@/types/interface";
import { getSession } from "@/utils";
import { USER } from "@/store/state";
import { systemBehaviorStore } from "@/utils/contantOptions";
interface SearchParams {
  name: string;
  status: string[];
  times: string[];
  product_id: null | number;
  end_time: string;
  start_time: string;
  sort_name: string;
  sort_by: number;
  page_size: number;
  page_index: number;
  month: string;
  staff_no: string;
  demand_id: number;
}

export default defineComponent({
  name: "qualityOverview",
  components: { IterationTip, ReportContent, FullScreen },
  props: {
    tableAutoHeight: {
      type: Boolean,
      default: false
    },
    // head判断是否显示搜索条件，分页
    head: {
      type: Boolean,
      default: () => true
    },
    pageSize: {
      type: Number,
      default: 20
    },
    page: {
      type: Boolean,
      default: () => false
    },
    searchCriteria: {
      type: Object,
      default: () => ({})
    },
    showPagDetail: {
      type: Boolean,
      default: true
    },
    showOption: {
      type: Boolean,
      default: true
    },
    isWatchProductCollect: {
      // 不监听项目集变化
      type: Boolean,
      default: true
    }
  },
  setup(props, { emit }) {
    // const { productLists } = replaceProductId();

    const isOpen = ref(false);
    const isFullScreen = ref(false);

    const pageTableRef = ref();

    const user = getSession(systemBehaviorStore.systemUserinfo, true) as USER;

    const showCurrentPage = ref(1);

    const currentActive = ref("-1");

    const searchParams = ref<SearchParams | any>({
      name: "",
      status: [],
      times: [],
      product_id: [],
      end_time: "",
      start_time: "",
      sort_name: "status",
      sort_by: 1,
      page_size: props.pageSize || 20,
      page_index: 1,
      month: "",
      staff_no: "",
      demand_id: 0
    });

    const defaultSearchParams: Record<string, any> = {
      name: "",
      status: [],
      times: [],
      product_id: [],
      end_time: "",
      start_time: "",
      sort_name: "status",
      sort_by: 1,
      page_size: 20,
      page_index: 1,
      month: "",
      staff_no: "",
      demand_id: 0
    };

    const router = useRouter();
    const route = useRoute();

    const shortcuts = reactive([
      {
        text: "最近一周",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
          return [start, end];
        }
      },
      {
        text: "最近一个月",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
          return [start, end];
        }
      },
      {
        text: "最近三个月",
        value: () => {
          const end = new Date();
          const start = new Date();
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
          return [start, end];
        }
      }
    ]);

    const { useGetMultipleProductStaffList, useInitGetProductList, boardBaseRt, disabledDate, useGetMultipleProductDemandList } = useBoardBase();

    const { getData, tableData, stopAutoGetData, currentPage } = useGetTableData(useFetchSearch, Object.assign(searchParams.value, props.searchCriteria));
    // 监听项目集数据变化
    const watchProdCollect = watch(
      () => boardBaseRt.currentProIds,
      (newVal) => {
        if (newVal && newVal.length) {
          searchParams.value.product_id = newVal;
          nextTick(() => {
            handleConditionSearch();
            getSearchData();
          });
        }
      },
      {
        deep: true,
        immediate: true
      }
    );
    // 进度判断落后还是提前
    const curStatus = computed(function () {
      return function (row: Record<string, any>) {
        const { complete_percent, time_percent } = row;
        return complete_percent - time_percent;
      };
    });
    // 表格行颜色设置  迭代状态：0待开始，1设计中，2开发中，3联调中，4测试中，5待发版，6已发版，7已搁置，8待测试
    const tableRowClassName = ({ row }: { row: Record<string, any>; rowIndex: number }) => {
      // 延期
      if (row.is_delay) {
        if (row.status !== 6 && row.status !== 7 && row.status !== 0) return "is-delay";
      }

      if (row.status === 0) {
        return "no-start";
      } else if (row.status === 6 || row.status === 7) {
        return "has-end";
      }
      return "to-do";
    };
    // 指派人列表
    const employeeLists = ref<MultipleStaffItem[]>([]);
    // 需求列表
    const newDemandLists = ref<MultipleDemandItem[]>([]);
    // 获取搜索组件指派人，需求列表数据
    const getSearchData = async () => {
      // 是否监听项目集
      if (!props.isWatchProductCollect || !boardBaseRt.currentProIds.length) return;
      const data = await useGetMultipleProductStaffList({ product_id: boardBaseRt.currentProIds });

      if (data) {
        employeeLists.value = data;
        searchArray.searchForm[2].listData = data;
      }

      const data1 = await useGetMultipleProductDemandList({ product_id: boardBaseRt.currentProIds });
      if (data1) {
        newDemandLists.value = data1;
        searchArray.searchForm[3].listData = newDemandLists.value;
      }
    };

    // 设置项目集的ids
    const setIds = (currentProIds: string, currentGroupIds: string) => {
      const proIds = currentProIds?.split(",")?.map((i) => parseInt(i));
      const groupIds = currentGroupIds?.split(",")?.map((i) => parseInt(i));
      Array.isArray(proIds) && (boardBaseRt.currentProIds = proIds);
      Array.isArray(groupIds) && (boardBaseRt.currentGroupIds = groupIds);
    };

    onMounted(async () => {
      // 是否监听项目集
      if (!props.isWatchProductCollect) {
        watchProdCollect();
      }
      await useInitGetProductList();
      const { month = "", currentProIds, currentGroupIds } = route.query as Record<string, any>;
      if (month) {
        currentActive.value = "99";
        setIds(currentProIds, currentGroupIds);
        searchParams.value.start_time = dayjs(month).startOf("month").format("YYYY-MM-DD");
        searchParams.value.end_time = dayjs(month).endOf("month").format("YYYY-MM-DD");
        searchParams.value.times = [searchParams.value.start_time, searchParams.value.end_time];
      } else if (boardBaseRt.currentProIds.length && props.isWatchProductCollect) {
        searchParams.value.product_id = boardBaseRt.currentProIds;
      }

      if (!Object.keys(props.searchCriteria).length || month) {
        getSearchData();
      }

      if (props.head) {
        getData();
      }
    });

    const getStatus = (index: number) => {
      const status = STATUS.find((v) => v.value === index);
      return status?.label;
    };
    let timer: ReturnType<typeof setTimeout>;
    const handleConditionSearch = async (isHiddenSelect?: boolean) => {
      if (isHiddenSelect === true) {
        return;
      }
      pageTableRef.value?.setCurrentPage();
      currentPage.value = 1;
      clearTimeout(timer);
      timer = setTimeout(async () => {
        // 获取当前page_size，如果有缓存，不设置，没有缓存默认第一页
        await getData({ pageIndex: 1, pageSize: 20 }, undefined, searchParams.value);
        stopAutoGetData.value = false;
      }, 600);
    };
    nextTick(() => {
      setTimeout(() => {
        const contentHeight = document.getElementsByClassName("content")[0] as HTMLDivElement;
        const headerHeight = document.getElementsByClassName("search-header")[0] as HTMLDivElement;
        pageTableRef.value.height = contentHeight.offsetHeight - headerHeight.offsetHeight - 130;
      }, 500);
    });
    const progressNode = (row: Record<string, any>) => {
      let createTime = row.plan ? row.plan.create_time : "";
      return [
        {
          time: createTime,
          realTime: createTime
        },
        {
          time: row.dev_time,
          realTime: row.real_dev_time
        },
        {
          time: row.union_time,
          realTime: row.real_union_time
        },
        {
          time: row.test_time,
          realTime: row.real_test_time
        },
        {
          time: row.release_time,
          realTime: row.real_release_time
        }
      ];
    };
    const handleClear = () => {
      searchParams.value.name = "";
      handleConditionSearch();
    };
    const handleShowDetail = (val: Record<string, any>) => {
      updateInterId(val, "homepage");
    };
    // 查看成员  在月度总览模块传月份，质量概览不需要
    const handleLookPeople = (val: Record<string, any>) => {
      const { href } = router.resolve({
        path: "/lookBoard/personal/jobEvaluation",
        query: {
          productId: val.product_id,
          iterationId: val.iteration_id
        }
      });
      window.open(href, "_blank");
    };
    const currentIteration = reactive<{ id: number; name: string }>({
      id: 0,
      name: ""
    });
    // 查看周报
    const handleLookReport = (val: Record<string, any>) => {
      isOpen.value = true;
      currentIteration.id = val.iteration_id;
      currentIteration.name = val.version_name;
    };
    const handleStatus = (val: number[]) => {
      if (!val.length) {
        searchParams.value.status = [];
        handleConditionSearch();
      }
    };
    // 时间选择时间
    const handleEndTime = (val: string[]) => {
      if (val && val.length) {
        searchParams.value.start_time = val[0];
        searchParams.value.end_time = val[1];
      } else {
        searchParams.value.start_time = "";
        searchParams.value.end_time = "";
      }
      handleConditionSearch();
    };

    // 监听项目id
    watch(
      () => props.searchCriteria,
      (newValue) => {
        if (newValue && newValue.product_id?.length) {
          searchParams.value.month = newValue.month;
          searchParams.value.product_id = newValue.product_id;
          searchParams.value.page_index = 1;
          searchParams.value.name = newValue.name;
          showCurrentPage.value = 1;
          handleConditionSearch();
          setTimeout(() => {
            emit("count", tableData.total);
          }, 1000);
        }
      },
      {
        deep: true,
        immediate: true
      }
    );

    watch(
      () => route.query,
      () => {
        const { where, productId, currentProIds, currentGroupIds } = route.query as Record<string, string>;
        if (where) {
          const proIds = currentProIds?.split(",")?.map((i) => parseInt(i));
          const groupIds = currentGroupIds?.split(",")?.map((i) => parseInt(i));
          Array.isArray(proIds) && (boardBaseRt.currentProIds = proIds);
          Array.isArray(groupIds) && (boardBaseRt.currentGroupIds = groupIds);
          currentActive.value = "99";

          setTimeout(() => {
            // 去掉路由的参数
            router.replace({
              path: route.path,
              query: { productId, where: undefined, month: undefined }
            });
          }, 700);
        }
      }
    );

    // 搜索列表数据
    const searchArray: SearchArray | Record<string, any> = reactive({
      btnArray: [
        { id: "-1", label: "所有", key: "" },
        { id: "0,1,2,3,4,5,7,8", label: "未完成", key: "status" },
        { id: "6", label: "已发布", key: "status" },
        { id: user?.staff_no, label: "我参与的", key: "staff_no" },
        { id: "99", label: "本月", key: "month" },
        { id: "999", label: "上月", key: "month" }
      ],
      searchForm: [
        {
          type: "input",
          label: "名称",
          key: "name",
          val: ""
        },
        {
          type: "select",
          label: "状态",
          multiple: true,
          key: "status",
          val: [],
          listData: STATUS
        },
        {
          type: "select",
          label: "成员",
          val: [],
          key: "staff_no",
          multiple: false,
          valueKey: ["staff_no", "staff_name"],
          listData: []
        },
        {
          type: "select",
          label: "关联需求",
          multiple: false,
          val: "",
          key: "demand_id",
          valueKey: ["id", "name"],
          listData: []
        },
        {
          type: "daterange",
          label: "时间",
          val: [],
          hasShortcuts: true,
          key: "times",
          valueKey: []
        }
      ]
    });

    // 快速查询
    const quickSeacrh = async (val: Record<string, any>) => {
      // 重写将默认值赋给searchParams
      defaultSearchParams.sort_by = searchParams.value.sort_by;
      defaultSearchParams.sort_name = searchParams.value.sort_name;

      searchParams.value = JSON.parse(JSON.stringify(defaultSearchParams));
      // val.key不存在代表获取所有数据
      if (val.key) {
        if (val.id === "99") {
          searchParams.value.month = dayjs().format("YYYY-MM");
        } else if (val.id === "999") {
          searchParams.value.month = dayjs().add(-1, "month").startOf("month").format("YYYY-MM");
        } else if (val.key === "status") {
          searchParams.value.status = val.id.split(",");
        } else if (val.key === "staff_no") searchParams.value.staff_no = val.id;
      }
      searchParams.value.product_id = boardBaseRt.currentProIds;
      searchData(searchParams.value);
    };
    // 点击搜索弹框选中搜索条件查询
    const changeSearch = async (val: Record<string, any>) => {
      defaultSearchParams.sort_by = searchParams.value.sort_by;
      defaultSearchParams.sort_name = searchParams.value.sort_name;
      searchParams.value = JSON.parse(JSON.stringify(defaultSearchParams));
      // // 遍历将值更新到formParams
      for (var k in val) {
        searchParams.value[k] = val[k];
      }
      if (val.times && val.times.length) {
        searchParams.value.start_time = val.times[0];
        searchParams.value.end_time = val.times[1];
      } else {
        searchParams.value.start_time = "";
        searchParams.value.end_time = "";
      }
      searchParams.value.product_id = boardBaseRt.currentProIds;
      searchData(searchParams.value);
    };

    // quick存在代表是使用快速搜索
    const searchData = async (params?: any) => {
      pageTableRef.value.setCurrentPage();

      getData(pageTableRef.value.getCurrentPage(), undefined, params);
    };

    const pageGetData = (val: { pageSize: number; pageIndex: number }) => {
      searchParams.value.page_size = val.pageSize;
      defaultSearchParams.page_size = val.pageSize;
      getData(pageTableRef.value.getCurrentPage(), undefined, searchParams.value);
    };

    // 分页
    const changePage = (cur: "prev" | "next") => {
      if (cur === "prev") {
        if (showCurrentPage.value === 1) return;
        --showCurrentPage.value;
      } else {
        if (showCurrentPage.value === Math.ceil(tableData.allData.count / 5)) return;
        ++showCurrentPage.value;
      }
      searchParams.value.page_index = showCurrentPage.value;
      handleConditionSearch();
    };

    // 排序
    const sortChange = (e: any) => {
      const { prop, order } = e;
      if (!order) return;
      searchParams.value.sort_name = prop;
      searchParams.value.sort_by = order === "ascending" ? 1 : 2;
      getData(pageTableRef.value.getCurrentPage(), undefined, searchParams.value);
    };

    const getTextColor = (status: number) => {
      const list = STATUS.find((v: { value: number; label: string }) => v.value === status);
      return {
        color: list?.color
      };
    };

    return {
      disabledDate,
      curStatus,
      isFullScreen,
      getTextColor,
      getStatus,
      getData,
      handleShowDetail,
      progressNode,
      handleConditionSearch,
      STATUS,
      tableData,
      searchParams,
      pageTableRef,
      handleClear,
      handleStatus,
      tableRowClassName,
      // productLists,
      handleEndTime,
      handleLookReport,
      isOpen,
      handleLookPeople,
      currentIteration,
      sortChange,
      shortcuts,
      changePage,
      currentPage,
      showCurrentPage,
      searchArray,
      currentActive,
      quickSeacrh,
      changeSearch,
      pageGetData
    };
  }
});
